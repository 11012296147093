import {
  Button,
  Flex,
  Spacer,
  Text
} from '@chakra-ui/react'

import { Link } from 'react-router-dom'

function Nav() {
  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      p={1}
      color="white"
      // background='linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 55%, rgba(0,0,0,0.6839110644257703) 81%, rgba(0,0,0,0.16850490196078427) 100%)'
      background='black'
    >

      <Spacer />
      <Link to='/'>
        <Button variant='ghost' _hover={{ color: '#606060' }} _active={{ bg: '' }}
          p={1}>
          <Text
            padding={{ base: 0, lg: 4 }}
          >
              OCNA
          </Text>
        </Button>
      </Link>
      {/* <Button variant='ghost' _hover={{ color: '#606060' }} _active={{ bg: '' }} p={1}>
          <Link to='/projects'>
            <Text
              padding={{ base: 0, lg: 4 }}
            >
              Past Releases
            </Text>
          </Link>
        </Button> */}

      <Text>{'//'}</Text>
      <Link to='/about'>
        <Button variant='ghost' _hover={{ color: '#606060' }} _active={{ bg: '' }} p={1} >
          <Text
            padding={{ base: 0, lg: 4 }}
          >
              BAND
          </Text>
        </Button>
      </Link>
      {/* <Button variant='ghost' p={1}>
          <Link to='/future'>
            <Text
              padding={{ base: 0, lg: 4 }}
            >
              Future
            </Text>
          </Link>
        </Button> */}



    </Flex>
  )
}

export default Nav
