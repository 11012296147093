import { 
  Box,
  Center, 
  Flex, 
  Img
} from '@chakra-ui/react'

import Memberinfo from './Memberinfo'

import DalePicture from '../Assets/Dale.jpg'
import FionaPicture from '../Assets/Fiona.jpg'
import KarlPicture from '../Assets/Karl.jpg'
import MattPicture from '../Assets/Matt.jpg'
import oncaTrimmed from '../Assets/OCNA-trimmed-nbg.png'

function About(){
  return (
    <Box color='white'>
      <Flex flexDirection='column'>
        <Center>
          <Img mt='5' w={{ base: '80%',md: '20%' }} src={oncaTrimmed}  />
        </Center>
        <Box m='5%' mb='10%' my='0' maxWidth='none' flex={1}>
          <Center>
            <Flex direction={{ base: 'column', md: 'row' }}>
              <Memberinfo name='Fiona Robertson' picture={FionaPicture} roles='vocals' />
              <Memberinfo name='Karl Nilsson' picture={KarlPicture} roles='electronics // guitar // lapsteel // production' />
              <Memberinfo name='Dale Hudson' picture={DalePicture} roles='guitar // bass // synths' right={true}/>
              <Memberinfo name='Matt Burns' picture={MattPicture} roles='drums' right={true}/>
            </Flex>
          </Center>
        </Box>
      </Flex>
      
    </Box>
  )
}

export default About