import { Box, Center, Flex, Heading, Text } from '@chakra-ui/react'

function Privacy(){
  return (
    <Box m={20} fontSize='5vw' color='white'>
      <Flex flexDirection='column' justifyContent='space-evenly' h='50vh'>
        <Center flex={1}>
          <Heading fontSize='1em'>
          Privacy Policy
          </Heading>
        </Center>
        <Center flex={1} fontSize='0.8em'>
          <Text textAlign='center'>
            {'WE DON\'T COLLECT ANY OF YOUR DATA.'}
          </Text>
        </Center>
        <Center flex={1} fontSize='0.4em'>
          <Text>
            {'We just don\'t do that sort of thing around here...'}
          </Text>
        </Center>
      </Flex>
      
      
    </Box>
  )
}

export default Privacy