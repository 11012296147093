

import {
  Box,
  Center,
  Flex, 
  IconButton, 
  Text
} from '@chakra-ui/react'

import { 
  GrInstagram, 
  GrSpotify, 
  GrTwitter,
  GrFacebook,
  GrYoutube
} from 'react-icons/gr'

import { Link } from 'react-router-dom/cjs/react-router-dom.min'

function Footer(){
  return (
    <Box
      width='100vw'
      p={1}
      pt={2}
      // background='linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 55%, rgba(0,0,0,0.6839110644257703) 81%, rgba(0,0,0,0.16850490196078427) 100%)'
      background='black'
    >
      <Flex flexDirection={{ base: 'column', md: 'row' }} justifyContent='space-between'>
        <Box flex={{ base: '0 1 auto', md: '1' }} px={3.5} >
          <Text fontSize={{ base: '0.6em', md: '0.9em', lg: '1em' }} color='white' opacity='0.8' textAlign={{ base: 'center', md: 'left' }}>
            ©2022 OCNA
          </Text>
        </Box>
        <Box flex={{ base: '0', sm: '1', md: '2' }} m={0.5}>
          <Center>
            <Flex flexDirection='row' justifyContent='flex-start'>
              <Link to='/privacy' flex='1'>
                <Text fontSize={{ base: '0.6em', md: '0.9em', lg: '1em' }} color='white' opacity='0.8' px='10px' >
              privacy
                </Text>
              </Link>
              <Link to='/cookies' flex='1'>
                <Text fontSize={{ base: '0.6em', md: '0.9em', lg: '1em' }} color='white' opacity='0.8' px='10px' >
              cookies
                </Text>
              </Link>
              <Link to='/contact' flex='1'>
                <Text fontSize={{ base: '0.6em', md: '0.9em', lg: '1em' }} color='white' opacity='0.8' px='10px' >
              contact
                </Text>
              </Link>
            
            </Flex>
          </Center>
          
        </Box>
        <Box flex={{ base: '0 1 auto', md: '1' }} px={{ nase: '0', md: '3.5' }}>
          <Flex 
            mx={{ base: '25%', md: 0 }}
            flexDirection='row' 
            justifyContent={{ base: 'space-between', md: 'end' }} 
            fontSize={{ base: '1.1em', md: '1.2em', lg: '1.4em' }}
          >
            <IconButton
              m='0.05em'
              boxSize={{ base: '4vw', md: '2vw', lg: '2vh' }}
              color='grey'
              bg='rgba(0,0,0,0)'
              transitionProperty='color'
              transitionDuration='0.25s'
              transitionTimingFunction='ease-in-out'
              _hover={{ color: '#E1306C' }}
              onClick={()=>{
                window.open('https://www.instagram.com/ocnaband/', '_blank')
              }}
            >
              <GrInstagram
                size='100%'
              /> 
            </IconButton>

            <IconButton
              m='0.05em'
              boxSize={{ base: '4vw', md: '2vw', lg: '2vh' }}
              color='grey'
              bg='rgba(0,0,0,0)'
              transitionProperty='color'
              transitionDuration='0.25s'
              transitionTimingFunction='ease-in-out'
              _hover={{ color: '#1DB954' }}
              onClick={()=>{
                window.open('https://open.spotify.com/artist/0bvMSlWx2dO36zzjIII4os', '_blank')
              }}
            >
              <GrSpotify
                size='100%'
              /> 
            </IconButton>

            <IconButton
              m='0.05em'
              boxSize={{ base: '4vw', md: '2vw', lg: '2vh' }}
              color='grey'
              bg='rgba(0,0,0,0)'
              transitionProperty='color'
              transitionDuration='0.25s'
              transitionTimingFunction='ease-in-out'
              _hover={{ color: '#FF0000' }}
              onClick={()=>{
                window.open('https://www.youtube.com/channel/UC17gu1qzF9NaDP7xOUMAsCw', '_blank')
              }}
            >
              <GrYoutube
                size='100%'
              /> 
            </IconButton>
            <IconButton
              m='0.05em'
              boxSize={{ base: '4vw', md: '2vw', lg: '2vh' }}
              color='grey'
              bg='rgba(0,0,0,0)'
              transitionProperty='color'
              transitionDuration='0.25s'
              transitionTimingFunction='ease-in-out'
              _hover={{ color: '#4267B2' }}
              onClick={()=>{
                window.open('https://www.facebook.com/ocnaband', '_blank')
              }}
            >
              <GrFacebook
                size='100%'
              /> 
            </IconButton> 
            <IconButton
              m='0.05em'
              boxSize={{ base: '4vw', md: '2vw', lg: '2vh' }}
              color='grey'
              bg='rgba(0,0,0,0)'
              transitionProperty='color'
              transitionDuration='0.25s'
              transitionTimingFunction='ease-in-out'
              _hover={{ color: '#1DA1F2' }}
              onClick={()=>{
                window.open('https://twitter.com/Ocnaband', '_blank')
              }}
            >
              <GrTwitter
                size='100%'
              /> 
            </IconButton>

            
            
          </Flex>
          
        </Box>
      </Flex>
    </Box>
  )
}

export default Footer