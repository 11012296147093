import React from 'react'
import { Box, ChakraProvider, Flex } from '@chakra-ui/react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import theme from './theme'

import Nav from './Components/Nav'
import Main from './Components/Main'
import About from './Components/About'
import Footer from './Components/Footer'
import Privacy from './Components/Privacy'
import Cookies from './Components/Cookies'
import Contact from './Components/Contact'

function App() {
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Flex minHeight='100vh' flexDirection='column' justifyContent='space-between' >
          <Nav />
          <Box flex={1} height='100%' display='flex' flexDirection='column' justifyContent='center' >
            <Switch minHeight='100%' >
              <Route exact path='/' component={Main}/>
              <Route path='/about' component={About}/>
              <Route path='/privacy' component={Privacy}/>
              <Route path='/cookies' component={Cookies}/>
              <Route path='/contact' component={Contact} />
              <Route path='/secret' component={Main}/>
            </Switch>
          </Box>
          <Footer />
        </Flex>
      </BrowserRouter>
    </ChakraProvider>
  )
}

export default App
