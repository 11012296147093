import { Box, Center, Flex, Heading, Text } from '@chakra-ui/react'

function Cookies(){
  return (
    <Box m={20} fontSize='5vw' color='white'>
      <Flex flexDirection='column' justifyContent='space-evenly' h='50vh'>
        <Center flex={1}>
          <Heading fontSize='1em'>
          Cookies Policy
          </Heading>
        </Center>
        <Center flex={1} fontSize='0.8em'>
          <Text textAlign='center'>
            {'🍪 WE DON\'T COLLECT ANY COOKIES 🍪'}
          </Text>
        </Center>
        <Center flex={1} fontSize='0.4em'>
          <Text>
        I recommend eating them warm with icecream...
          </Text>
        </Center>
      </Flex>
    </Box>
  )
}

export default Cookies