import { IconButton } from '@chakra-ui/button'
import { Img } from '@chakra-ui/image'
import { Center } from '@chakra-ui/layout'
import { 
  Box,
  Text,
  Flex
} from '@chakra-ui/react'

import {
  BsFillPlayFill, 
  BsFillPauseFill
} from 'react-icons/bs'

import { Slider, SliderFilledTrack, SliderTrack } from '@chakra-ui/slider'
import { useRef, useState } from 'react'

import ocnaTrimmed from '../Assets/OCNA-trimmed-nbg.png'
import ocnaCant from '../Assets/OCNA-Cant.png'
import ocnaBecause from '../Assets/OCNA-because.jpeg'
// import trees from '../Assets/Trees.png'
// import whenBg from '../Assets/When_Square.jpg'
import Cantaudio from '../Assets/01-Cant.mp3'
import when from '../Assets/02-When.mp3'
import lessluminous from '../Assets/03-LessLuminous.mp3'
import ocnaLLPic from '../Assets/OCNA-LL.png'
import because from '../Assets/04-Because.mp3'
// import becauseVid from '../Assets/because.mov'

import { 
  GrYoutube, 
  GrSpotify
} from 'react-icons/gr'

import {
  SiDeezer,
  SiApplemusic,
  SiTidal
} from 'react-icons/si'

import {

  AiFillAmazonSquare
} from 'react-icons/ai'

function Main() {
  const [state, setState] = useState({
    playing: false,
    played: 0,
    volume: 0.1,
  })

  const {
    playing,
    played,
    volume,
  } = state

  // const { isOpen, onOpen, onClose } = useDisclosure()

  const [currentSong] = useState(2)

  const songs = [
    { 
      videolink: null, 
      audiolink: Cantaudio, 
      name: 'Can\'t', 
      Art: ocnaCant,
      deezerlink: 'https://www.deezer.com/en/album/292645442',
      spotifylink: 'https://open.spotify.com/album/1eqXix9xQKFRKNisbHJ14m', 
      ituneslink: 'https://music.apple.com/us/album/cant-single/1608315227?uo=4&app=itunes',
      applemusiclink: 'https://music.apple.com/us/album/cant-single/1608315227?uo=4&app=apple+music',
      youtubelink: 'https://www.youtube.com/watch?v=zQvVhU_5YPY',
      tidallink: 'https://tidal.com/track/215072551',
      amazonmusiclink: null,
      
    }, 
    { 
      videolink: null, 
      audiolink: when, 
      name: 'When', 
      Art: null,
      deezerlink: null,
      spotifylink: 'https://open.spotify.com/track/1cslIMkFhb6v2RlhD1yF0J?si=3bmwi9hMQOqU2b20sm6VQw', 
      ituneslink: null,
      applemusiclink: 'https://music.apple.com/album/1617545816?i=1617545817',
      youtubelink: null,
      tidallink: null,
      amazonmusiclink: null,

    }, 
    { 
      videolink: null, 
      audiolink: lessluminous, 
      name: 'Less Luminous',
      Art: ocnaLLPic,
      deezerlink: 'https://deezer.page.link/B9Vtjr6mNvwKS6VV8',
      spotifylink: 'https://open.spotify.com/track/317ElagPVUsucwyetwBsnA?si=a3b0fd00348f4409', 
      ituneslink: null,
      applemusiclink: 'https://music.apple.com/il/album/less-luminous-single/1644241881',
      youtubelink: 'https://www.youtube.com/watch?v=IfKM4cipDcA',
      tidallink: null,
      amazonmusiclink: 'https://music.amazon.co.uk/albums/B0BDQGDG4C?marketplaceId=A1F83G8C2ARO7P&musicTerritory=GB&ref=dm_sh_EVEt6xIRBCauf0d2UzVnuOjXY&trackAsin=B0BDQPCMJ6',
    }, 
    { 
      videolink: null, 
      audiolink: because, 
      name: 'Because', 
      Art: ocnaBecause,
      deezerlink: null,
      spotifylink: 'https://open.spotify.com/track/2HNH3qn0GlLvsvFgpXu4Gz', 
      ituneslink: null,
      applemusiclink: null,
      youtubelink: null,
      tidallink: null,
      amazonmusiclink: null,

    }
  ]

  const handleVolume = (e) => {
    setState({ ...state, volume: parseFloat(e / 100) })
    backgroundRef.current.volume = (parseFloat(e / 100))
  }

  const handlePlay = () => {
    setState({ ...state, playing: !state.playing })
    backgroundRef.current.paused ? backgroundRef.current.play() : backgroundRef.current.pause()
    backgroundRef.current.volume = state.volume
  }
  

  const handleProgress = () => {
    const played = backgroundRef.current.currentTime / backgroundRef.current.duration
    setState({ ...state, played: played })
  }

  const handleSeek = (e) => {
    backgroundRef.current.pause()
    setState({ ...state, played: parseFloat(e / 100) })
    backgroundRef.current.currentTime = parseFloat(e / 100) * backgroundRef.current.duration
  }

  const handleSeekUp = () => {
    if (state.playing) {
      backgroundRef.current.play()
      setState({ ...state, playing: true })
    } else {
      setState({ ...state, playing: false })
    }
    
    
  }
  
  const backgroundRef = useRef()
  return (
    <Flex minHeight='100%' flexDirection='column' justifyContent='space-between' >
      <video
        ref={backgroundRef}
        autoPlay={playing}
        onTimeUpdate={handleProgress}
        volume={volume}
        loop
        style={{
          position: 'absolute',
          size: 'cover',
          width: '100%',
          left: '50%',
          top: '50%',
          height: '100%',
          objectFit: 'cover',
          transform: 'translate(-50%, -50%)',
          zIndex: '-2',
        }}
      >
        <source src={songs[currentSong].audiolink} type='video/mp4' />
      </video>
      {songs[currentSong].videolink ?
        <video
          ref={backgroundRef}
          autoPlay={playing}
          onTimeUpdate={handleProgress}
          volume={0}
          loop
          style={{
            position: 'absolute',
            size: 'cover',
            width: '100%',
            left: '50%',
            top: '50%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center center',
            // transform: 'translate(-50%, -50%)',
            zIndex: '-1',
          }}
        >
          <source src={songs[currentSong].videolink} type='video/mp4' />
        </video>
        :

        <img
          src={songs[currentSong].Art}
          style={{
            display: { playing },
            position: 'absolute',
            size: 'cover',
            width: '100%',
            left: '50%',
            top: '50%',
            height: '100%',
            objectFit: 'cover',
            transform: 'translate(-50%, -50%)',
            zIndex: '-1',
            objectPosition: 'top',
          }} />
      }
      <Center>
        <Img m={{ base: '1em', md: '4em' }} w={{ base: '80%', md: '20%' }} src={ocnaTrimmed} />
        
      </Center>
      
      
      <Center >
        <Box my={{ base: 'em', md: '2em' }} w={{ base: '70vw', md: '40vh' }} position='relative'>
          
          
          {/* {
            songs[currentSong].Art ?
              <Image  src={songs[currentSong].Art} />
              :
              <Center><Text m='1em' fontSize='4em' color='white'>{songs[currentSong].name} </Text></Center>
          } */}
          <Center>
            <Text
              mt='1em'
              p='0.25em 0.5em' 
              borderRadius='0.5em'
              fontSize={{ base: '2em', md: '3em' }}
              color= 'white'
              background='blackAlpha.200'
              style={{
                'backdropFilter': 'blur(8px)',
              }}
              textAlign='center'
            >
            NEW SINGLE
            </Text>
          </Center>

          <Center>
            <Text
              mt='1em'
              p='0.25em 0.5em' 
              borderRadius='0.5em'
              fontSize={{ base: '2em', md: '3em' }}
              color= 'white'
              background='blackAlpha.200'
              style={{
                'backdropFilter': 'blur(8px)',
              }}
              textTransform='uppercase'
              textAlign='center'
            >
              {'"' + (songs[currentSong].name || 'name') + '"'} </Text>
          </Center>

          <Center>
            <Text
              mt='1em'
              p='0.25em 0.5em' 
              borderRadius='0.5em'
              fontSize={{ base: '2em', md: '3em' }}
              color= 'white'
              background='blackAlpha.200'
              style={{
                'backdropFilter': 'blur(8px)',
              }}
              textAlign='center'
            >
              OUT NOW </Text>
          </Center>
          

          {/* <Text
            
            color='white'
            position='absolute'
            fontSize={{ base: '4vw', md: '2vh' }}
            top='0.5em'
            left='0.5em'
          
          >{'DEBUT SINGLE:'}</Text>
          <Text
            color='white'
            position='absolute'
            fontSize={{ base: '4vw', md: '2vh' }}
            top='0.5em'
            right='0.5em'
          >
            {'CAN\'T'}</Text>
        
          <Text
            color='white'
            position='absolute'
            fontSize={{ base: '6vw', md: '3vh' }}
            top='2.5em'
            left='20%'
            right='20%'
            textAlign='center'
          >
            OUT NOW</Text> */}
        </Box>
        
      </Center>

      <Box
        style={{
          'backdropFilter': 'blur(8px)',
        }}
        background='blackAlpha.400'
        position='relative' 
        m='0 auto'
        my={{ base: '0.5em', md: '2em' }}
        padding='1em'
        borderRadius='1em'
        left={0}
        right={0}
        marginLeft='auto' 
        marginRight='auto'
      >
        <Center >
          
          {
            songs[currentSong].spotifylink ?
              <IconButton
                m='0.2em'
                boxSize={{ base: '5vw', lg: '5vh' }}
                color='white'
                opacity='0.8'
                bg='rgba(0,0,0,0)'
                transitionProperty='color, opacity'
                transitionDuration='0.25s'
                transitionTimingFunction='ease-in-out'
                _hover={{
                  color: '#1ed760',
                  opacity: '1',
                }}
                onClick={()=>{
                  window.open(songs[currentSong].spotifylink, '_blank')
                }}
              >
                <GrSpotify
                  size='100%'
                /> 
              </IconButton>
              : <></>  
          }
          {
            songs[currentSong].youtubelink ?
              <IconButton
                m='0.2em'
                boxSize={{ base: '5vw', lg: '5vh' }}
                color='white'
                opacity='0.8'
                bg='rgba(0,0,0,0)'
                transitionProperty='color, opacity'
                transitionDuration='0.25s'
                transitionTimingFunction='ease-in-out'
                _hover={{
                  color: '#FF0000',
                  opacity: '1',
                }}
                onClick={()=>{
                  window.open(songs[currentSong].youtubelink, '_blank')
                }}
              >
                <GrYoutube
                  size='100%'
                /> 
              </IconButton>
              : <></>  
          }
          {
            songs[currentSong].deezerlink ?
              <IconButton
                m='0.2em'
                boxSize={{ base: '5vw', lg: '5vh' }}
                color='white'
                opacity='0.8'
                bg='rgba(0,0,0,0)'
                transitionProperty='color, opacity'
                transitionDuration='0.25s'
                transitionTimingFunction='ease-in-out'
                _hover={{
                  color: '#19c798',
                  opacity: '1',
                }}
                onClick={()=>{
                  window.open(songs[currentSong].deezerlink, '_blank')
                }}
              >
                <SiDeezer
                  size='100%'
                /> 
              </IconButton>
              : <></>  
          }
          {
            songs[currentSong].applemusiclink ?
              <IconButton
                m='0.2em'
                boxSize={{ base: '5vw', lg: '5vh' }}
                color='white'
                opacity='0.8'
                bg='rgba(0,0,0,0)'
                transitionProperty='color, opacity'
                transitionDuration='0.25s'
                transitionTimingFunction='ease-in-out'
                _hover={{
                  color: '#fc3c44',
                  opacity: '1',
                }}
                onClick={()=>{
                  window.open(songs[currentSong].applemusiclink, '_blank')
                }}
              >
                <SiApplemusic
                  size='100%'
                /> 
              </IconButton>
              : <></>  
          }
          {
            songs[currentSong].tidallink ?
              <IconButton
                m='0.2em'
                boxSize={{ base: '5vw', lg: '5vh' }}
                color='white'
                opacity='0.8'
                bg='rgba(0,0,0,0)'
                transitionProperty='color, opacity'
                transitionDuration='0.25s'
                transitionTimingFunction='ease-in-out'
                _hover={{
                  color: 'white',
                  opacity: '1',
                }}
                onClick={()=>{
                  window.open(songs[currentSong].tidallink, '_blank')
                }}
              >
                <SiTidal
                  size='100%'
                /> 
              </IconButton>
              : <></>  
          }{
            songs[currentSong].amazonmusiclink ?
              <IconButton
                m='0.2em'
                boxSize={{ base: '5vw', lg: '5vh' }}
                color='white'
                opacity='0.8'
                bg='rgba(255,255,255,0.0)'
                transitionProperty='color, opacity'
                transitionDuration='0.25s'
                transitionTimingFunction='ease-in-out'
                borderRadius='20px'
                overflow='hidden'


                _hover={{
                  color: '#FF9900',
                  opacity: '1',
                }}
                onClick={()=>{
                  window.open(songs[currentSong].amazonmusiclink, '_blank')
                }}
              >
                <AiFillAmazonSquare
                  size='100%'
                  width='100%'
                  height='100%'
                /> 
              </IconButton>
              : <></>  
          }
          
        </Center>
      </Box>
      

      <Box
        style={{
          'backdropFilter': 'blur(8px)',
        }}
        background='blackAlpha.400'
        position='relative' 
        width={{ base: '80%', md: '40%' }}
        margin='0 auto'
        padding='1em'
        borderRadius='1em'
        left={0}
        right={0}
        marginLeft='auto' 
        marginRight='auto'
      >
        <Center>
          <Slider
            width='100%'
            colorScheme="green"
            size='md'
            m='3'
            value={played * 100}
            onChange={handleSeek}
            onChangeEnd={handleSeekUp}
          >
            <SliderTrack
              transitionProperty='height'
              transitionDuration='0.1s'
              transitionTimingFunction='ease-in-out'
              _hover={{ height: 2.5 }}
              rounded={100}
            >
              <SliderFilledTrack />
            </SliderTrack>
          </Slider>
        </Center>
        <Center>
        
          <Box position='relative'  width='100%' px='2em' >
            <Flex justifyContent='space-around' alignItems='center' >
              <Box flex='2'>
                <Center>
                  <Text
                    fontSize={{ base: '3vw', md: '1.75vw' }}
                    color='white'
                    textAlign='end'
                  >
                    {songs[currentSong].name}
                  </Text>
                </Center>
              
              </Box>
              <Box flex='1'>
                <Center>
                  {/* <IconButton
                    flex='0.4'
                    colorScheme='whiteAlpha'
                    variant='ghost'
                    _active={{ bg: '', outline: 0 }}
                    fontSize={{ base: '8vw', md: '4vw' }}
                    w='2vw'
                    h='100%'
                    icon={<BsSkipStartFill color='grey'/>}
                  /> */}

                  <IconButton
                    flex='0.4'
                    colorScheme='whiteAlpha'
                    variant='ghost'
                    _active={{ bg: '', outline: 0 }}
                    fontSize={{ base: '8vw', md: '4vw' }}
                    w='2vw'
                    h='100%'
                    onClick={handlePlay}
                    icon={playing ? <BsFillPauseFill color='white' opacity='0.8' /> : <BsFillPlayFill color='white' opacity='0.8' />}
                  />

                  {/* <IconButton
                    flex='0.4'
                    colorScheme='whiteAlpha'
                    variant='ghost'
                    _active={{ bg: '', outline: 0 }}
                    fontSize={{ base: '8vw', md: '4vw' }}
                    w='2vw'
                    h='100%'
                    icon={<BsSkipEndFill color='grey'/>}
                  /> */}
                </Center>
                
              </Box>
              
              <Box flex='2'>
                <Slider
                  bottom={1}
                  colorScheme="green"
                  size='md'
                  value={volume * 100}
                  onChange={handleVolume}
              
                >
                  <SliderTrack
                    transitionProperty='height'
                    transitionDuration='0.1s'
                    transitionTimingFunction='ease-in-out'
                    _hover={{ height: 2.5 }}
                    rounded={100}
                  >
                    <SliderFilledTrack />
                  </SliderTrack>
                </Slider>
              </Box>
              
            </Flex>
          
          </Box>
        </Center>
          
      </Box>

      {/*//! Fix the side panel for mobile */}
      {/* <Button 
        colorScheme='blackAlpha' 
        onClick={onOpen} 
        // onMouseOver={onOpen}
        position='absolute'
        right={0}
        top='40px'
        height='90%'
        width='10vw'
        backgroundColor={'rgba(0,0,0,0)'}
        
      >
        <BsFilterRight color='white' fontSize={30} />
      </Button>
      <Box >
        <Drawer
          isOpen={isOpen}
          placement='right'
          onClose={onClose}
        
        >
          <DrawerOverlay />
          <DrawerContent
            // onMouseLeave={onClose}
          >
            <DrawerCloseButton />
            <DrawerHeader>Songs:</DrawerHeader>

            <DrawerBody>
              <span>
                <GiSoundWaves fontSize={60}/>
              </span>
              
              <Text>
                When
              </Text>
              
            </DrawerBody>

            <DrawerFooter>
            </DrawerFooter>
          </DrawerContent>
        </Drawer> 
      </Box>*/}
      
    </Flex>
  )
}

export default Main