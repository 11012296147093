import { 
  Box, 
  Center, 
  Flex, 
  Img, 
  Text 
} from '@chakra-ui/react'

function Memberinfo({ name, picture, roles, right }){
  return (
    <Box m='5' width={{ base: '80vw', sm: '50vw', md: '20vw' }}>
      <Flex direction='column'>
        {right ?
          <Text fontSize={{ base: '5vw', md: '2vw' }} textAlign='end' >
            {name}
          </Text>
          : <Text fontSize={{ base: '5vw', md: '2vw' }}>
            {name}
          </Text>
        }
        <Img 
          src={picture} 
          alt={name}
          objectFit='cover'
          
        />
        <Center>
          <Text fontSize={{ base: '4vw', sm: '2.5vw' ,md: '1vw' }} textAlign='center'>
            {roles}
          </Text>
        </Center>
        
      </Flex>
    </Box>
  )
}

export default Memberinfo