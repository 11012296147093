import { extendTheme } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'


const config = {
  initialColorMode: 'dark',
  useSystemColorMode: false,

}

const styles = {
  global: props => ({
    body: {
      color: mode('gray.800', 'whiteAlpha.900')(props),
      
      bg: '#000',
    },
    html: {
      height: '100%',
    },

  }),
}

const theme = extendTheme({
  config,
  styles,
  fonts: {
    heading: 'Roboto Serif',
    body: 'Roboto Serif',
  },
  
})

export default theme