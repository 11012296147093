import { 
  Box, 
  Flex, 
  Heading, 
  IconButton, 
  Text 
} from '@chakra-ui/react'
import { GrFacebook, GrInstagram, GrSpotify, GrTwitter, GrYoutube } from 'react-icons/gr'

function Contact(){
  return (
    <Box m={20} fontSize={{ base: '2em', md: '3em' }} color='white'>
      <Heading fontSize='1em'>
          Contact us:
      </Heading>
      <Flex flexDirection='column' justifyContent='space-evenly' h='40vh'>
        <Text fontSize='0.6em'>
          The easiest way to reach us is through social media!
        </Text>
        <Box  px={{ nase: '0', md: '3.5' }}>
          <Flex 
            mx={{ base: '25%', md: '0%' }}
            flexDirection='row' 
            justifyContent={{ base: 'space-between', md: 'start' }} 
            fontSize={{ base: '1.5em', md: '1.2em', lg: '1.4em' }}
            
          >
            <IconButton
              m={{ base: '0.05em', md: '0.25em' }}
              boxSize={{ base: '6vw', md: '6vw', lg: '4vh' }}
              color='grey'
              bg='rgba(0,0,0,0)'
              transitionProperty='color'
              transitionDuration='0.25s'
              transitionTimingFunction='ease-in-out'
              _hover={{ color: '#E1306C' }}
              onClick={()=>{
                window.open('https://www.instagram.com/ocnaband/', '_blank')
              }}
            >
              <GrInstagram
                size='100%'
              /> 
            </IconButton>

            <IconButton
              m={{ base: '0.05em', md: '0.25em' }}
              boxSize={{ base: '6vw', md: '6vw', lg: '4vh' }}
              color='grey'
              bg='rgba(0,0,0,0)'
              transitionProperty='color'
              transitionDuration='0.25s'
              transitionTimingFunction='ease-in-out'
              _hover={{ color: '#1DB954' }}
              onClick={()=>{
                window.open('https://open.spotify.com/artist/0bvMSlWx2dO36zzjIII4os', '_blank')
              }}
            >
              <GrSpotify
                size='100%'
              /> 
            </IconButton>

            <IconButton
              m={{ base: '0.05em', md: '0.25em' }}
              boxSize={{ base: '6vw', md: '6vw', lg: '4vh' }}
              color='grey'
              bg='rgba(0,0,0,0)'
              transitionProperty='color'
              transitionDuration='0.25s'
              transitionTimingFunction='ease-in-out'
              _hover={{ color: '#FF0000' }}
              onClick={()=>{
                window.open('https://www.youtube.com/channel/UC17gu1qzF9NaDP7xOUMAsCw', '_blank')
              }}
            >
              <GrYoutube
                size='100%'
              /> 
            </IconButton>
            <IconButton
              m={{ base: '0.05em', md: '0.25em' }}
              boxSize={{ base: '6vw', md: '6vw', lg: '4vh' }}
              color='grey'
              bg='rgba(0,0,0,0)'
              transitionProperty='color'
              transitionDuration='0.25s'
              transitionTimingFunction='ease-in-out'
              _hover={{ color: '#4267B2' }}
              onClick={()=>{
                window.open('https://www.facebook.com/ocnaband', '_blank')
              }}
            >
              <GrFacebook
                size='100%'
              /> 
            </IconButton> 
            <IconButton
              m={{ base: '0.05em', md: '0.25em' }}
              boxSize={{ base: '6vw', md: '6vw', lg: '4vh' }}
              color='grey'
              bg='rgba(0,0,0,0)'
              transitionProperty='color'
              transitionDuration='0.25s'
              transitionTimingFunction='ease-in-out'
              _hover={{ color: '#1DA1F2' }}
              onClick={()=>{
                window.open('https://twitter.com/Ocnaband', '_blank')
              }}
            >
              <GrTwitter
                size='100%'
              /> 
            </IconButton>

            
            
          </Flex>
          
        </Box>
        <Text fontSize='0.6em'>
        Email: ocnaband@gmail.com
        </Text>
      </Flex>
      
      
    </Box>
  )
}

export default Contact